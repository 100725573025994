import React from "react";
import { Link } from "gatsby";

import SVG from "react-inlinesvg";

import C from "classnames";

import style from "./index.module.styl";

import { ROUTES } from "../../common/routes.js";

export interface IBreadcrumb {
  readonly text: string;
  readonly link?: string;
}

interface IBreadcrumbsProps {
  readonly breadcrumbs: ReadonlyArray<IBreadcrumb>;
}

export default function Breadcrumbs({
  breadcrumbs,
}: IBreadcrumbsProps) {
  return (
    <div className={style.items}>
      {breadcrumbs.map((x, i) => (
        <Link
          key={x.text}
          to={x.link == "/" ? ROUTES.home() : x.link || ""}
          className={C(style.item, {
            [style.itemActive]: i === breadcrumbs.length - 1,
          })}
        >
          <div className={style.itemIcon}>
            <SVG src={require("../../images/icons/breadcrumb.svg")} />
          </div>
          <div className={style.itemText}>{x.text}</div>
        </Link>
      ))}
    </div>
  );
}
