import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link } from "gatsby";
import style from "./index.module.styl";

import SVG from "react-inlinesvg";

import C from "classnames";
import { LINKS } from "..";
import { useGlobalStore } from "../../../store";
import { ROUTES } from "../../../common/routes.js";
import { useEffectAsync } from "../../../utils/effects";

interface ITopMenuMobileProps {
  readonly isFixed?: boolean;
  readonly className?: string;
  readonly isMaintenanceMode?: boolean;
}

export default function TopMenuMobile({
  isFixed,
  className,
  isMaintenanceMode,
}: ITopMenuMobileProps) {
  const [isOpen, setIsOpen] = useState(false);

  const { i18n, makeLink, matchCurrentPath } = useGlobalStore();

  const translate = useRef(0);
  const currentScrollPosition = useRef(0);

  const menuRef = useRef<HTMLDivElement>(null);
  const [menuHeight, setMenuHeight] = useState<number | undefined>(undefined);
  const didFirstRun = useRef(false);

  const setTranslate = useCallback((menuHeight: number) => {
    if (!menuRef.current) {
      return;
    }
    menuRef.current.style.transform = `translateY(-${(
      translate.current * menuHeight
    ).toFixed(2)}px)`;
  }, []);

  useEffectAsync(
    (isMounted: () => boolean) => {
      if (!menuRef.current) {
        return;
      }

      let didRun = false;

      function run() {
        if (!isMounted()) {
          return;
        }

        const height = menuRef.current.scrollHeight;

        if (height !== menuHeight) {
          setMenuHeight(height);
        }
      }

      function onResize() {
        if (!didRun) {
          didRun = true;
          run();
          setTimeout(() => {
            didRun = false;
            run();
          }, 50);
        }
      }
      window.addEventListener("resize", onResize);

      if (!didFirstRun.current) {
        didFirstRun.current = true;
        run();
      }

      return () => window.removeEventListener("resize", onResize);
    },
    [menuHeight],
  );

  useEffect(() => {
    if (!menuRef.current) {
      return;
    }

    if (menuHeight === undefined) {
      return;
    }

    translate.current = 0;

    function onScroll(e: any) {
      if (window.scrollY < 0) {
        translate.current = 0;
      } else {
        const deltaY = window.scrollY - currentScrollPosition.current;

        const ratioY = Math.abs(deltaY / menuHeight);

        if (deltaY > 0) {
          translate.current += ratioY;

          if (translate.current > 1) {
            translate.current = 1;
          }
        } else {
          translate.current -= ratioY;
          if (translate.current < 0) {
            translate.current = 0;
          }
        }
        currentScrollPosition.current = window.scrollY;
      }

      setTranslate(menuHeight);
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [menuRef.current, menuHeight]);

  return (
    <>
      <div
        ref={menuRef}
        className={C(style.menu, { [style.menuFixed]: !!isFixed }, className)}
      >
        <div className={C(style.menuSide, style.menuBurger)}>
          {!isMaintenanceMode && (
            <div
              role="button"
              onClick={() => setIsOpen(prevState => !prevState)}
            >
              <SVG src={require("../../../images/icons/burger-menu.svg")} />
            </div>
          )}
        </div>
        <Link to={makeLink(ROUTES.home())} className={style.menuCenter}>
          <SVG src={require("../../../images/logo.svg")} />
        </Link>
        <div className={style.menuSide}></div>
      </div>
      {!isMaintenanceMode && (
        <div
          className={C(style.menuOverlay, { [style.menuOverlayOpen]: isOpen })}
        >
          <div className={style.menuOverlayHeader}>
            <button
              className={style.menuOverlayClose}
              onClick={() => setIsOpen(prevState => !prevState)}
            >
              <SVG src={require("../../../images/icons/close.svg")} />
            </button>
          </div>
          <div className={style.items}>
            {LINKS.map(x => (
              <Link
                key={x.key}
                to={makeLink(x.link)}
                className={C(style.item, {
                  [style.itemActive]: matchCurrentPath(makeLink(x.link)),
                })}
              >
                {i18n.t(x.key)}
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
