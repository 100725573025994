import React from "react";
import style from "./index.module.styl";
import C from "classnames";

interface IProgressDesktopProps {
  readonly items: string[];
  readonly index: number;
  readonly className?: string;
  readonly onClick?: (index: number) => void;
}

export default function ProgressDesktop({
  items,
  index,
  className,
  onClick,
}: IProgressDesktopProps) {
  return (
    <div className={C(style.progress, className)}>
      {items.map((x, i) => (
        <div
          key={i}
          className={C(style.progressItem, {
            [style.progressItemPassed]: i < index,
            [style.progressItemActive]: i === index,
          })}
        >
          <button
            onClick={() => onClick && onClick(i)}
            className={style.progressItemText}
          >
            {x}
          </button>
        </div>
      ))}
    </div>
  );
}
