import React, {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback,
} from "react";
import { makeLink, matchCurrentPath } from "../utils/routing";
import {
  IGatsbyPageProps,
  IGatbsyTranslatable,
  ISitemapPage,
} from "../common/gatsby";
import { IBreadcrumb } from "../components/breadcrumbs";
import { IOption } from "shared/lib/models/model";
import { ITherapy } from "shared/lib/models/therapy";
import { IConfiguration } from "shared/lib/models/configuration";
import {
  IInitializedI18n,
  AvailableLocales,
  initializeI18n,
} from "shared/lib/common/i18n";
import {
  IBillLine,
  ITranslatedBillLine,
  translateBill,
} from "shared/lib/utils/order";
import { getConfigurationOptionValue } from "shared/lib/utils/option";
import {
  getTherapyOptionLabel,
  getChosenTherapiesLabel,
  getTherapyLabel,
} from "shared/lib/utils/therapy";
import { ITherapyOption } from "shared/lib/models/therapy";

export interface IGlobalStateOptionHelper {
  readonly getConfigurationOptionValue: (option: IOption) => string;
}

export interface IGlobalStateTherapyHelper {
  readonly getChosenTherapiesLabel: (option: IConfiguration) => string;
  readonly getTherapyLabel: (option: ITherapy) => string;
  readonly getTherapyOptionLabel: (therapyOption: ITherapyOption) => string;
}

export interface IGlobalStateOrderHelper {
  readonly translateBill: (bill: IBillLine[]) => ITranslatedBillLine[];
}

export interface IGlobalStateOptionHelper {
  readonly getConfigurationOptionValue: (option: IOption) => string;
}

export interface IGlobalStateTherapyHelper {
  readonly getChosenTherapiesLabel: (option: IConfiguration) => string;
  readonly getTherapyLabel: (option: ITherapy) => string;
}

export interface IGlobalStateOrderHelper {
  readonly translateBill: (bill: IBillLine[]) => ITranslatedBillLine[];
}

export interface IGlobalStateSitemapPage {
  readonly title: string;
  readonly link: string;
  readonly hiddenFromSitemap: boolean;
  readonly pages?: ReadonlyArray<IGlobalStateSitemapPage>;
}

interface IGlobalState {
  readonly locale: AvailableLocales;
  readonly i18n: IInitializedI18n;
  readonly makeLink: (link: string) => string;
  readonly pathname: string;
  readonly matchCurrentPath: (link: string) => boolean;
  readonly breadcrumbs: ReadonlyArray<IBreadcrumb>;
  readonly sitemap: ReadonlyArray<IGlobalStateSitemapPage>;
  readonly asyncScriptsLoaded: boolean;
  readonly optionHelpers: IGlobalStateOptionHelper;
  readonly therapyHelpers: IGlobalStateTherapyHelper;
  readonly orderHelpers: IGlobalStateOrderHelper;
}

const GlobalContext = createContext<IGlobalState | undefined>(undefined);

interface IGlobalStateProps {
  readonly children: any;
  readonly initialLocale: AvailableLocales;
  readonly gatsbyPageProps: IGatsbyPageProps<any>;
  readonly asyncScriptsLoaded: boolean;
}

function translateGatsbyTranslatable(
  i18n: IInitializedI18n,
  translatable: IGatbsyTranslatable,
) {
  if (translatable.key) {
    return i18n.t(translatable.key as any, translatable.props || undefined);
  } else if (translatable.text) {
    return translatable.text;
  }

  return "translation_failed";
}

function makeSitemapPage(
  i18n: IInitializedI18n,
  makeLink: (link: string) => string,
  page: ISitemapPage,
): IGlobalStateSitemapPage {
  return {
    title: translateGatsbyTranslatable(i18n, page.title),
    link: makeLink(page.link),
    hiddenFromSitemap: !!page.hiddenFromSitemap,
    pages:
      page.pages?.map(x => makeSitemapPage(i18n, makeLink, x)) || undefined,
  };
}

export function GlobalState({
  children,
  initialLocale,
  gatsbyPageProps,
  asyncScriptsLoaded,
}: IGlobalStateProps) {
  const [locale] = useState<AvailableLocales>(initialLocale);

  const _i18n = useMemo(() => {
    return initializeI18n(locale);
  }, [locale]);

  const _makeLink = useCallback(
    (link: string) => {
      return makeLink(locale, link);
    },
    [locale],
  );

  const _matchCurrentPath = useCallback((link: string) => {
    return matchCurrentPath(gatsbyPageProps.location.pathname, link);
  }, []);

  const _breadcrumbs = useMemo<Breadcrumbs>(() => {
    return gatsbyPageProps.pageContext.breadcrumbs.map(x => ({
      text: translateGatsbyTranslatable(_i18n, x.title),
      link: makeLink(locale, x.link),
    }));
  }, [_i18n]);

  const _sitemap = useMemo<ReadonlyArray<IGlobalStateSitemapPage>>(() => {
    return gatsbyPageProps.pageContext.sitemap.map(x =>
      makeSitemapPage(_i18n, _makeLink, x),
    );
  }, [_i18n, _makeLink]);

  const _optionHelper = useCallback(
    (option: IOption) => {
      return getConfigurationOptionValue(_i18n, option);
    },
    [_i18n],
  );

  const _therapyHelpers: IGlobalStateTherapyHelper = useMemo(() => {
    return {
      getChosenTherapiesLabel: (configuration: IConfiguration) =>
        getChosenTherapiesLabel(_i18n, configuration),
      getTherapyLabel: (therapy: ITherapy) => getTherapyLabel(_i18n, therapy),
      getTherapyOptionLabel: (therapyOption: ITherapyOption) =>
        getTherapyOptionLabel(_i18n, therapyOption),
    };
  }, [_i18n]);

  const _orderHelpers: IGlobalStateOrderHelper = useMemo(() => {
    return {
      translateBill: bill => translateBill(_i18n, bill),
    };
  }, [_i18n]);

  return (
    <GlobalContext.Provider
      value={{
        locale,
        i18n: _i18n,
        makeLink: _makeLink,
        pathname: gatsbyPageProps.location.pathname,
        matchCurrentPath: _matchCurrentPath,
        breadcrumbs: _breadcrumbs,
        sitemap: _sitemap,
        asyncScriptsLoaded,
        optionHelpers: {
          getConfigurationOptionValue: _optionHelper,
        },
        therapyHelpers: _therapyHelpers,
        orderHelpers: _orderHelpers,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export const useGlobalStore = () =>
  useContext<IGlobalState>(GlobalContext as any);
