import React, { useCallback } from "react";

import { Link } from "gatsby";

import style from "./index.module.styl";

import SVG from "react-inlinesvg";
import { LINKS } from "..";
import { useGlobalStore } from "../../../store";

import C from "classnames";
import { ROUTES } from "../../../common/routes.js";
import ProgressDesktop from "../../progress/desktop";
import { ORDER_STEPS } from "../../../common/payment";
import { useMenuContext } from "../../layouts/general/context/menu";

interface ITopMenuDekstopProps {
  readonly menuIsFixed: boolean;
  readonly isMenuOrder?: boolean;
  readonly orderStepIndex?: number;
  readonly canNavigateNext?: boolean;
  readonly onNavigateNext?: () => void;
  readonly onNavigateBack?: () => void;
}

function Logo() {
  const { makeLink } = useGlobalStore();
  return (
    <Link to={makeLink(ROUTES.home())} className={style.menuLogo}>
      <SVG src={require("../../../images/logo.svg")} />
    </Link>
  );
}

function StandardDesktop() {
  const { i18n, makeLink, matchCurrentPath } = useGlobalStore();
  return (
    <>
      <Logo />
      <div className={style.menuItems}>
        {LINKS.map(
          x =>
            !x.mobileOnly && (
              <Link
                key={x.key}
                to={makeLink(x.link)}
                className={C(style.menuItem, {
                  [style.menuItemActive]: matchCurrentPath(makeLink(x.link)),
                })}
              >
                {i18n.t(x.key)}
              </Link>
            ),
        )}
      </div>
    </>
  );
}

function OrderDesktop() {
  const { i18n } = useGlobalStore();

  const { orderStepIndex = 0, onClick } = useMenuContext();

  return (
    <>
      <Logo />
      <div className={style.menuOrder}>
        <ProgressDesktop
          className={style.menuOrderProgress}
          items={ORDER_STEPS.map(x => i18n.t(x.i18nKey))}
          index={orderStepIndex}
          onClick={onClick}
        />
      </div>
      <div className={style.menuLogoSizedPlaceholder} />
    </>
  );
}

export default function TopMenuDesktop({ menuIsFixed }: ITopMenuDekstopProps) {
  const menuClass = menuIsFixed ? style.menuFixed : style.menu;

  const { isMenuOrder } = useMenuContext();
  return (
    <div className={`${style.menu} ${menuClass}`}>
      {isMenuOrder ? <OrderDesktop /> : <StandardDesktop />}
    </div>
  );
}
