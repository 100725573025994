import { createContext, useContext } from "react";

export interface IMenuState {
  readonly isMenuOrder: boolean;
  readonly orderStepIndex?: number;
  readonly onClick?: (index: number) => void;
}

export const MenuContext = createContext<IMenuState | undefined>(undefined);

export const useMenuContext = () => useContext<IMenuState>(MenuContext as any);
