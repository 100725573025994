import React from "react";
import SVG from "react-inlinesvg";
import { Link } from "gatsby";

import style from "./index.module.styl";

import { ROUTES } from "../../common/routes.js";
import { SOCIAL_MEDIAS } from "../../common/socialMedias.js";
import { useGlobalStore } from "../../store";

interface IProps {
  isMaintenanceMode?: boolean;
}

export default function Footer({ isMaintenanceMode }: IProps) {
  const { i18n, makeLink } = useGlobalStore();

  return (
    <div className={style.footer}>
      <div className={style.links}>
        <div className={style.linksLeft}>
          {!isMaintenanceMode && (
            <>
              <Link className={style.link} to={makeLink(ROUTES.shipping())}>
                {i18n.t("footer_faq_text")}
              </Link>
              <Link className={style.link} to={makeLink(ROUTES.tnc())}>
                {i18n.t("footer_tnc_text")}
              </Link>
              <Link className={style.link} to={makeLink(ROUTES.sitemap())}>
                {i18n.t("footer_sitemap_text")}
              </Link>
            </>
          )}
        </div>

        <div className={style.linksRight}>
          {SOCIAL_MEDIAS.map(socialMedia => (
            <a
              href={i18n.t(`social_link_${socialMedia}`)}
              target="_blank"
              rel="noopener noreferrer"
              className={style.socialMediaLink}
              key={socialMedia}
            >
              <SVG src={require(`../../images/icons/${socialMedia}.svg`)} />
            </a>
          ))}
        </div>
      </div>
      <div className={style.copyright}>
        {i18n.t("footer_copyright_text", { year: new Date().getFullYear() })}
      </div>
    </div>
  );
}
