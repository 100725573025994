import { ROUTE_REGEX, PATH_WIHTOUT_QS_REGEX } from "../common/regexes";
import { AvailableLocales } from "shared/lib/common/i18n";

const routeRegex = new RegExp(ROUTE_REGEX);
const pathWithoutQS = new RegExp(PATH_WIHTOUT_QS_REGEX);

export function changePageLocale(newLocale: AvailableLocales) {
  const match = window.location.pathname.match(routeRegex);
  if (match) {
    return `/${newLocale}${match[2] || ""}`;
  }

  return undefined;
}

export function makeLink(locale: AvailableLocales, link: string) {
  return `${link}`;
}

function makePart(key: string, value: string) {
  return encodeURIComponent(key) + "=" + encodeURIComponent(value);
}

export function makeQueryLink(
  baselink: string,
  queryArguments: { [key: string]: string | string[] },
) {
  let query = [];

  for (const key of Object.keys(queryArguments)) {
    const value = queryArguments[key];

    if (Array.isArray(value)) {
      query.push(
        value.reduce((prev, current) => prev + makePart(key, current), ""),
      );
    } else {
      query.push(makePart(key, value));
    }
  }

  return baselink + (query.length > 0 ? "?" + query.join("&") : "");
}

export function parseQuery(path: string) {
  const pathSplit = path.split("?");

  if (pathSplit.length === 1 || pathSplit[1].trim() === "") {
    return {};
  }

  return ((pathSplit[1]
    .split("&")
    .map(x => {
      const queryPartSplit = x.split("=");

      if (queryPartSplit.length === 1) {
        return undefined;
      }

      return queryPartSplit.map(y => y.trim());
    })
    .filter(x => x) as unknown) as string[]).reduce<{
    [key: string]: string[];
  }>((prev, current) => {
    if (!prev[current[0]]) {
      prev[current[0]] = [];
    }
    prev[current[0]].push(current[1]);

    return prev;
  }, {});
}

export function matchCurrentPath(currentPath: string, link: string) {
  const matchLink = link.match(pathWithoutQS);

  if (!matchLink) {
    return false;
  }

  const matchCurrent = currentPath.match(pathWithoutQS);

  if (!matchCurrent) {
    return false;
  }

  return matchLink[1] === matchCurrent[1];
}
