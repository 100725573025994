import { ROUTES } from "../../common/routes.js";
import { ILocale } from "shared/lib/locales/base";
interface IMenuLink {
  readonly key: keyof ILocale;
  readonly link: string;
  readonly mobileOnly?: boolean;
}

export const LINKS: ReadonlyArray<IMenuLink> = [
  { key: "menu_home_text", link: ROUTES.home(), mobileOnly: true },
  { key: "menu_collections_text", link: ROUTES.collections() },
  { key: "menu_therapies_text", link: ROUTES.therapies() },
  { key: "menu_about_text", link: ROUTES.about() },
  { key: "menu_contact_text", link: ROUTES.contact() },
];
