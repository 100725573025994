import React, { useEffect, useState, useRef } from "react";
import C from "classnames";

import style from "./index.module.styl";
import TopMenuDesktop from "../../topMenu/desktopView";
import TopMenuMobile from "../../topMenu/mobileView";
import Breadcrumbs, { IBreadcrumb } from "../../breadcrumbs";
import Footer from "../../footer";
import { MenuContext, useMenuContext } from "./context/menu";

interface IGeneralLayoutProps {
  readonly children?: any;
  readonly breadcrumbs?: ReadonlyArray<IBreadcrumb>;
  readonly renderTopMenu?: any;
  readonly alignCenter?: boolean;
  readonly isMenuOrder?: boolean;
  readonly orderStepIndex?: number;
  readonly onProgressClick?: (index: number) => void;
  readonly isFullWidth?: boolean;
  readonly isFooterFullWidth?: boolean;
  readonly isBodyFullWidth?: boolean;
  readonly isMaintenanceMode?: boolean;
}

interface IStandardTopMenuProps {
  readonly menuDesktopClass?: string;
}

function StandardTopMenu({ menuDesktopClass }: IStandardTopMenuProps) {
  const { isMenuOrder } = useMenuContext();

  return (
    <>
      {!isMenuOrder && (
        <div className={style.menuMobile}>
          <TopMenuMobile />
        </div>
      )}
      <div className={C(style.menuDesktop, menuDesktopClass)}>
        <TopMenuDesktop menuIsFixed={!!menuDesktopClass} />
      </div>
    </>
  );
}

export default function GeneralLayout({
  children,
  breadcrumbs,
  renderTopMenu,
  alignCenter = false,
  isMenuOrder = false,
  orderStepIndex,
  isFullWidth = false,
  isFooterFullWidth = false,
  isBodyFullWidth = false,
  onProgressClick,
  isMaintenanceMode,
}: IGeneralLayoutProps) {
  const higherThreshold = 70;
  const lowerThreshold = 20;
  const [menuDesktopClass, setMenuDesktopClass] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const { scrollY } = window;

      if (!!menuDesktopClass && scrollY >= lowerThreshold) {
        return;
      }
      setMenuDesktopClass(
        scrollY >= higherThreshold ? style.menuDesktopFixed : "",
      );
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const RenderTopMenu = renderTopMenu;

  return (
    <MenuContext.Provider
      value={{
        isMenuOrder,
        orderStepIndex,
        onClick: onProgressClick,
      }}
    >
      <div
        className={C(style.container, {
          [style.containerFullWidth]: isFullWidth,
        })}
      >
        {!!RenderTopMenu ? (
          <RenderTopMenu isMaintenanceMode={isMaintenanceMode} />
        ) : (
          <StandardTopMenu />
        )}
        <div
          className={C(style.bodyWrapper, {
            [style.bodyWrapperFullWidth]: isBodyFullWidth,
          })}
        >
          <div className={C(style.body, { [style.bodyCenter]: alignCenter })}>
            {!!breadcrumbs && (
              <div className={style.breadcrumbs}>
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </div>
            )}
            {children}
          </div>
        </div>

        <div
          className={C(style.footerWrapper, {
            [style.footerWrapperFullWidth]: isFooterFullWidth,
          })}
        >
          <Footer isMaintenanceMode={isMaintenanceMode} />
        </div>
      </div>
    </MenuContext.Provider>
  );
}
