
export function updateAnalyticsDefinition(
    addToCartId:string,
    initiateCheckoutId:string,
    bathCustomizationId:string, 
    orderConfirmationId:string,
    paymentInformationId:string
    ) {
    globalThis.analyticsMapping = {
        AddToCartId: addToCartId,
        InitiateCheckoutId: initiateCheckoutId,
        BathCustomizationId: bathCustomizationId,
        OrderConfirmationId: orderConfirmationId,
        PaymentInformationId: paymentInformationId
    }
}

export function sendGAAnalytics(window: any, event: string, action: string, dataObject?: Object) {
    if (typeof window !== "undefined" && window.gtag) {
        window.gtag(event, action, dataObject)
    }
}
export function sendFBPixel( action: string, dataObject?: Object) {
    fbq('track', action, dataObject);        
}

export function sendAddToCartEvent(window: any, modelName: String) {
    sendGAAnalytics(window, 'custom_event', globalThis.analyticsMapping.AddToCartId, modelName)
}
export function sendPurchaseEvent(window: any, orderRequest?: Object) {
    sendGAAnalytics(window, 'custom_event', globalThis.analyticsMapping.OrderConfirmationId, orderRequest)
}
export function sendInitiateCheckoutEvent(window: any, orderRequest?: Object) {
    sendGAAnalytics(window, 'custom_event', globalThis.analyticsMapping.InitiateCheckoutId, orderRequest)
}
export function sendAddPaymentInfoEvent(window: any, orderRequest?: Object) {
    sendGAAnalytics(window, 'custom_event', globalThis.analyticsMapping.PaymentInformationId, orderRequest)
}
export function sendCustomizeProductEvent(window: any, configuration?: Object) {
    sendGAAnalytics(window, 'custom_event', globalThis.analyticsMapping.BathCustomizationId, configuration )
}
