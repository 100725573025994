import { ILocale } from "shared/lib/locales/base";

enum OrderStep {
  information = "information",
  payment = "payment",
  confirmation = "conformation",
}

interface IOrderStep {
  readonly key: OrderStep;
  readonly i18nKey: keyof ILocale;
}

export const ORDER_STEPS: ReadonlyArray<IOrderStep> = [
  { key: OrderStep.information, i18nKey: "order_progress_informations" },
  { key: OrderStep.payment, i18nKey: "order_progress_payment" },
  { key: OrderStep.confirmation, i18nKey: "order_progress_confirmation" },
];
