import { useEffect, useRef } from "react";

export function useEffectAsync(
  callback: (isMounted: () => boolean) => void,
  dependencies: React.DependencyList,
) {
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    callback(() => isMounted.current);
  }, dependencies);
}
