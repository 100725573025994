import React, { useEffect, useRef, useState, useCallback } from "react";

import "../../styles/app.styl";
import { GlobalState } from "../../store";
import { IGatsbyPageProps } from "../../common/gatsby";
import { useEffectAsync } from "../../utils/effects";
import { AvailableLocales } from "shared/lib/common/i18n";
import { useStaticQuery, graphql } from "gatsby";
import { updateAnalyticsDefinition } from "../../common/analytics";
import GeneralLayout from "../layouts/general";
import HomeLanding from "../homeLanding";
import Maintenance from "./maintenance";

interface IPageProps {
  readonly pageContext: {
    readonly initialLocale: AvailableLocales;
  };
  readonly data: {
    readonly strapiAnalytics: {
      readonly AddToCartId: string;
      readonly InitiateCheckoutId: string;
      readonly BathCustomizationId: string;
      readonly orderConfirmationId: string;
      readonly PaymentInformationId: string;
    };
  };
  [key: string]: any;
}

const isMaintenanceMode = false;

export default function page(Component: any) {
  return function Page(props: IPageProps & IGatsbyPageProps<any>) {
    const {
      pageContext: { initialLocale },
    } = props;

    const analytics = useStaticQuery(graphql`
      {
        strapiAnalytics {
          BathCustomizationId
          AddToCartId
          InitiateCheckoutId
          orderConfirmationId
          PaymentInformationId
        }
      }
    `);
    updateAnalyticsDefinition(
      analytics.strapiAnalytics.AddToCartId,
      analytics.strapiAnalytics.InitiateCheckoutId,
      analytics.strapiAnalytics.BathCustomizationId,
      analytics.strapiAnalytics.orderConfirmationId,
      analytics.strapiAnalytics.PaymentInformationId,
    );

    const scriptsRef = useRef<HTMLDivElement>(null);
    const didBegin = useRef(false);
    const [didLoad, setDidLoad] = useState(false);

    useEffect(() => {
      if (initialLocale != AvailableLocales.en) {
        // BU-281: French version will come later && SiteMap plugin config must be adjusted accordingly
        window.location.pathname = ``;
      }
    }, []);

    useEffectAsync(
      isMounted => {
        if (!scriptsRef.current) {
          return;
        }

        if (didBegin.current) {
          return;
        }

        didBegin.current = true;

        const script = document.createElement("script");

        script.src = "/js/inputmask.min.js";
        script.async = true;
        script.onload = () => {
          if (!isMounted()) {
            return;
          }
          setDidLoad(true);
        };

        scriptsRef.current.appendChild(script);
      },
      [scriptsRef.current],
    );

    useEffect(() => {
      function handleMouseDown() {
        document.body.classList.add("using-mouse");
        document.body.classList.remove("using-tab");
      }

      function handleKeyDown(e: KeyboardEvent) {
        if (e.keyCode === 9) {
          document.body.classList.remove("using-mouse");
          document.body.classList.add("using-tab");
        }
      }

      document.body.addEventListener("mousedown", handleMouseDown);
      document.body.addEventListener("keydown", handleKeyDown);

      return () => {
        document.body.removeEventListener("mousedown", handleMouseDown);
        document.body.removeEventListener("keydown", handleKeyDown);
      };
    }, []);

    return (
      <GlobalState
        initialLocale={initialLocale}
        gatsbyPageProps={props}
        asyncScriptsLoaded={didLoad}
      >
        <div ref={scriptsRef}></div>
        {!isMaintenanceMode && <Component {...props} />}
        {isMaintenanceMode && (
          <GeneralLayout
            renderTopMenu={HomeLanding}
            isFullWidth
            isMaintenanceMode={isMaintenanceMode}
          >
            <Maintenance />
          </GeneralLayout>
        )}
      </GlobalState>
    );
  };
}
