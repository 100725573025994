function kebabify(text) {
  return text
    ? text
        .replace(/([a-z])([A-Z])/g, "$1-$2")
        .replace(/\s+/g, "-")
        .toLowerCase()
    : "";
}

const ROUTES = {
  home: () => process.env.GATSBY_LEADPAGES_URL,
  contact: () => `${process.env.GATSBY_LEADPAGES_URL}/contact`,
  collections: () => "/collections",
  shipping: () => `${process.env.GATSBY_LEADPAGES_URL}/shipping`,
  tnc: () => `${process.env.GATSBY_LEADPAGES_URL}/tnc`,
  therapies: () => `${process.env.GATSBY_LEADPAGES_URL}/therapies`,
  about: () => `${process.env.GATSBY_LEADPAGES_URL}/about`,
  therapy: therapyName => `${process.env.GATSBY_LEADPAGES_URL}/${kebabify(therapyName)}`,
  sitemap: () => "/sitemap",
  notFound: () => "/404",
  internalError: () => "/500",
  payment: () => "/pay",
  collection: collectionName => `/${kebabify(collectionName)}`,
  model: (collectionName, modelName) =>
    `/${kebabify(collectionName)}/${kebabify(modelName)}`,
  order: () => "/order",
  admin: () => "/admin",
};

module.exports = {
  ROUTES: ROUTES,
  kebabify: kebabify,
};
